.Component {
    text-align: center;
    background-color: #AA8800;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    /*font-size: calc(10px + 2vmin);*/
  }

.Component-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.Component-nav {
    display: inline-block;
    float:right;
    list-style-type: none;
    padding: 1em 2em 0 0;
    font-weight: 300;
    letter-spacing: 1px;
  }

.nav-logo {
    float: left;
    height: 3em;
    padding: 1em 0 0 2em;
}

.Component-nav li {
    text-align: right;
    font-size: .6em;
    padding: 0 1em;
}

.Component-nav a {
    color: white;
}

h3 {
    font-family: 'Raleway', sans-serif;
    text-transform: lowercase;
    font-weight: 100;
    font-size: 2vw;
    line-height: 1vh;
}

h2 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 4vw;
    line-height: 1vh;
}

@media only screen and (max-width: 767px) {
    .nav-logo {
        float: left;
        height: 3em;
        padding: 2.5em 0 0 2em;
    }

    h3 {
        font-size:.7em;
        line-height: 1vh;
    }
    h2 {
        font-size: 1.4em;
        line-height: 1vh;
    }
}
