.Home {
    text-align: center;
    background-image: url("floss.jpg");
    background-color: #1D1D1D;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: #FFCC00;
    /*font-size: calc(10px + 2vmin);*/
  }
  
  .border {
    /*align-items: center;
    justify-content: center;*/
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid white;
    width: calc(100vw - 3em);
    height: calc(100vh - 3em);
  }
  
  .content {
    height: 0vh;
    margin: calc((100vh/2 - 1.5em) - 15vh) 0;
  }
  
  .header {
    display: inline-block;
  }
  
  .logo {
    float: left;
    height: 9vw;
    padding: 0 3vw;
  }
  
  h1 {
    font-size: 6vw;
    font-weight: 200;
    letter-spacing: 2px;
    float: left;
    margin: 0;
    padding-top: .25em;
  }
  
  .Home-nav {
    display: inline-block;
    list-style-type: none;
    padding: 0;
    font-weight: 300;
    letter-spacing: 1px;
  }
  
  li {
    float: left;
    text-align: center;
    font-size: 2vw;
    padding: 0 1em;
  }

  a {
      color: #FFCC00;
      text-decoration: none;
  }
  
  @media only screen and (max-width: 1199px) {
  
  }
  
  @media only screen and (max-width: 991px) {
  
  }
  
  @media only screen and (max-width: 767px) {
    h1 {
      display: none;
    }
    
    .header {
      width: 80vw;
    }
  
    .logo {
      float: none;
      height: 5em;
    }
  
   li {
      float: none;
      line-height: 2em;
      font-size: 1em;
    }
  }
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    
  }